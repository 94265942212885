import axios from "axios";
import route from '../router/index';
import store from '../store/index';
import qs from 'qs'

const instance = axios.create({
  // baseURL: "https://test-upac.muugame.com",
  baseURL: "/",
});
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8'
// 请求拦截器
instance.interceptors.request.use(
  (config) => {
    // 在请求发送之前做一些处理
    // if (config.method == "post") {
    //   config.headers['Content-Type'] = config.headers['Content-Type'] + "application/x-www-form-urlencoded;multipart/form-data"
    // }
    // if (localStorage.getItem("token")) {
    //   config.headers.Authorization = JSON.parse(localStorage.getItem("token"));
    // } else {
    //   config.headers.Authorization = route.currentRoute.query.data
    // }
    config.data = qs.stringify(config.data)
    return config;
  },
  (error) => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 响应拦截器
instance.interceptors.response.use(
  (response) => {
    // 对响应数据做一些处理
    if (response.data.code == 206) {
      localStorage.removeItem("token");
      localStorage.removeItem("userid");
      localStorage.removeItem("iframeURL");
      store.state.token = "";
      store.state.iframeURL = "";
      router.push({
        path: "/login",
        query: { game_id: localStorage.getItem("game_id") },
      });
      store.commit("openSdk", true);
    }
    return response.data;
  },
  (error) => {
    // 对响应错误做些什么
    return Promise.reject(error);
  }
);

export default instance;
