module.exports = {
  // 按钮
  btn: {
    confirm: '确认',
    cancel: '取消',
    close: '关闭',
    back: '返回'
  },
  // 登录页
  login: {
    email: '邮箱',
    emailPlacehold: '请输入邮箱',
    phone: '手机',
    phonePlacehold: '请输入手机号',
    password: '密码',
    passwordPlacehold: '请输入密码',
    signIn: '登录',
  },
  // tabbar 
  tabbar: {
    t1: '充值',
    t2: '消费',
    t3: '个人'
  },
  // 选择游戏
  game: {
    gameTitle: '游戏列表'
  },
  // 消费页（主页）
  consume: {
    bindServeTitle: '选择区服',
    bindRoleTitle: '选择角色',
    bindGiftTitle: '选择礼包类型',
    bindDefault: '请选择'
  },
  // 个人中心
  center: {
    username: '账号',
    coin: '余额',
    bind_coin: '绑币',
    task: '任务中心',
    coupon: '优惠券',
    unblock: '解封',
    tabGame: '切换游戏',
    logOut: '退出登录'
  },
  // gate
  gate: {
    serial: '卡号',
    serialDefault: '请输入卡号',
    IDCard: '身份证',
    IDCardDefault: '请输入身份证',
    submit: '充值'
  },
  // 充值
  recharge: {
    telCard: '电话卡',
    gate: 'Gate卡'
  },
  // 计费点
  product: {
    pay: '确认购买',
    coupon: '优惠券',
    payType: '支付方式',
    coin: '余额',
    otherPayment: '其他支付',
    recharge: '充值',
    couponDisabled1: '不可使用',
    couponDisabled2: '此付款方式不能使用优惠券',
    payTypeDisabledInf1: '余额不够买当前礼包',
    payTypeDisabledInf2: '优惠券不支持此付款方式',
    payTypeDefault: '请选择'
  },
  // 优惠券
  coupon: {
    effectiveTime: '有效时间',
    timeAvailable: '可用时间',
    deliver: '送',
    reduce: '减',
    paymentChannel: '可用支付渠道',
    remainingNum: '次可用',
    maxTitle: '满',
    getCoupon: '领取'
  },
  // 任务中心
  task: {
    content1: '活动期间玩家在官方网页充值渠道消费{val}越南盾，即可领取优惠券奖励',
    content2: '活动期间玩家在官方网页充值渠道累计消费达到指定档位可以获得对应的优惠券',
    content3: '活动期间玩家在官方网页充值渠道消费时可以获得对应的优惠券',
    content4: '活动期间下载并成功登陆{game}的玩家, 即可领取优惠券奖励',
    remark: '备注：使用优惠券消费及平台币消费的整笔订单不计入消费任务',
    unacommpolished: '未达成',
    received: '已领取',
    receivedSuccess: '领取成功',
    receivedErr: '领取失败,请联系客服',
    availableChannels: '可用渠道',
    time_title: '距结束',
    time_d: '天',
    time_h: '小时',
    time_m: '分',
    time_s: '秒',
    accum1: '当前连续已充值 <span>{val}</span> 天',
    accum2: '当前已充值 <span>{val}</span> VNĐ',
    accum4: '当前已登录 <span>{val}</span> 天 ',
    childAccum1: '第 {val} 天',
    childAccum2: '累计消费 {val} VNĐ',
    childAccum3: '充值 {min} VNĐ - {max} VNĐ',
    childAccum4: '登录 {val} 天',
    reward: '奖励',
    btn1: '领取',
    btn2: '已领取',
    btn3: '未达成',
    instruction: '说明',
    couponDate: '收到后 {day} 天内有效'
  }
}

