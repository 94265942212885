<template>
  <div class="Navbar" :style="{'backgroundColor': navColor}">
    <div @click="back()" class="Navbar_btn">
      <SvgIcon v-if="isBack" class="Navbar_btn_back" name="arrow-left" color="#000" />
    </div>
    <div>{{title}}</div>
    <div class="Navbar_btn"></div>
  </div>
</template>
<script>

export default {
  data(){
    return {
    }
  },
  props: {
    navColor: {
      type: String,
      default: '#ffffff'
    },
    title: {
      type: String,
      default: '标题'
    },
    isBack: {
      type: Boolean,
      default: false 
    },
  },
  methods: {
    back () {
      if (this.isBack) {
        this.$router.go(-1)
      }
    }
  }
}
</script>
<style scoped>
.Navbar{
  position: relative;
  z-index: 30;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: .8rem;
  box-shadow: 0 0 5px #ddd;
  font-size: .3rem;
}
.Navbar_btn{
  display: flex;
  align-items: center;
  padding: 0 .1rem;
  width: 1rem;
  height: 100%;
}
.Navbar_btn_back{
  width: .4rem;
  height: .4rem;
}
</style>