import { createStore } from "vuex";

export default createStore({
  state: {
    openSdk: false, // sdk 开关
    messageShow: false, // 消息弹框 显示/隐藏
    messageOpen: false, // 消息弹框 开关
    message: "", // 消息内容
    webUUID: "",
    initData: {},
    iframeURL: "",
    token: "",
    fb_appid:'',//fbid
    client_id:'',//谷歌id
    userInfo:{},//用户信息
    game_id:"",
    sdkShow:false,
  },
  mutations: {
    openSdk(state, val) {
      state.openSdk = val;
    },
    messageOpen(state, val) {
      state.messageOpen = val;
    },
    messageShow(state, val) {
      state.messageShow = val;
    },
    message(state, val) {
      state.message = val;
    },
  },
  actions: {},
  getters: {
    openSdk: (state) => {
      return state.openSdk;
    },
    messageOpen: (state) => {
      return state.messageOpen;
    },
    messageShow: (state) => {
      return state.messageShow;
    },
    message: (state) => {
      return state.message;
    },
  },
  modules: {},
});
