<template>
  <div class="_app">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {
    
    };
  },
  created() {
  },
  mounted() {
  },
  watch: {
  },
  methods: {
    
  },
};
</script>
<style>
</style>
