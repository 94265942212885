<template>
    <div @click="msgClose" class="popup popup_center" :class="{'popup_center_on': messageOpen}">
      <div @click.stop class="popup_box">
        <div class="popup_content">
            {{message}}
        </div>
        <div @click="msgClose" class="popup_btn btn_close">{{$t('btn.close')}}</div>
      </div>
    </div>
</template>
<script>

export default {
    data(){
        return {
        }
    },
    computed: {
      messageOpen () { return this.$store.getters.messageOpen },
      message () { return this.$store.getters.message },
    },
    methods: {
        msgClose () { // 关闭msg
          this.$store.commit('messageOpen', false)
          setTimeout(() => {
            this.$store.commit('messageShow', false)
            this.$store.commit('message', '')
          }, 300);
        }
    }
}
</script>
<style scoped>
.popup_content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: .1rem .18rem;
  min-height: 2rem;
  box-sizing: border-box;
  text-align: center;
}
.popup_btn{
  width: 1.8rem;
  line-height: .56rem;
  text-align: center;
  margin: .3rem 0;
  border-radius: .3rem;
  cursor: pointer;
}
</style>